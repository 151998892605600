import {computed} from "vue";
import {useStorage} from "@vueuse/core";
import dayjs from "dayjs";
import {ofetch} from "ofetch";
import {apiBaseUrl} from "@/utils/fetch-utils";
import {useRouter} from "vue-router";

const accessTokenLocalStorageItem = useStorage('access-token', '')
const tokenExpLocalStorageItem = useStorage('tokenExp', '')

export function useAuth() {
    const router = useRouter();

    const isAuthenticated = computed(() => {
        if (accessTokenLocalStorageItem.value === '' || tokenExpLocalStorageItem.value === '') return false
        return dayjs().isBefore(dayjs(tokenExpLocalStorageItem.value))
    })

    const logOut = async () => {
       try{
           await ofetch('/users/logout',{
               method: "POST",
               baseURL: apiBaseUrl,
               body: {
                   access_token: accessTokenLocalStorageItem.value
               }
           })
       }
       catch (error) {
           // push.error('Failed to log out')
       }
       finally {
           accessTokenLocalStorageItem.value = ''
           tokenExpLocalStorageItem.value = ''

           await router.push({
               path: '/sign-in'
           })

       }
    }

    return {
        logOut,
        isAuthenticated
    }
}