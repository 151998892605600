<!-- // notivue item data -->
<!--{-->
<!--"ref_for": true,-->
<!--"title": "",-->
<!--"message": "No air now sensor locations found for current map position",-->
<!--"duration": 10000,-->
<!--"ariaLive": "assertive",-->
<!--"ariaRole": "alert",-->
<!--"id": "0",-->
<!--"type": "error",-->
<!--"props": {},-->
<!--"createdAt": 1738430605464,-->
<!--"duplicateCount": 0-->
<!--}-->

<template>
  <div
    class="rounded-2xl shadow-xl flex flex-col overflow-hidden md:w-[24rem] "
    @mouseenter="pauseProgress"
    @mouseleave="resumeProgress"
  >
    <!-- Toast Header -->
    <div class="py-4 px-5 flex gap-3 font-medium bg-white text-black">
      <div class="flex-1 flex gap-4 items-center capitalize">
        <Icon
          v-if="item?.type === 'error'"
          icon="material-symbols:dangerous"
          class="size-6 text-slate-500 shrink-0"
        />
        <Icon
          v-if="item?.type === 'success'"
          icon="ix:success-filled"
          class="size-6 text-slate-500 shrink-0"
        />
        <Icon
          v-if="item?.type === 'warning'"
          icon="jam:triangle-danger-f"
          class="size-6 text-slate-500 shrink-0"
        />
        {{ item?.message }}
      </div>

      <!-- Close Button -->
      <div class="flex items-center">
        <button
          class="p-1 hover:bg-gray-200 rounded-lg hover:text-black"
          @click="item?.destroy"
        >
          <Icon
            icon="heroicons:x-mark-16-solid"
            class="size-6 text-black"
          />
        </button>
      </div>
    </div>

    <!-- Progress Bar -->
    <!--    <div class="relative w-full bg-white h-2 rounded-full overflow-hidden">-->
    <!--      <div-->
    <!--        class="absolute top-0 left-0 h-full transition-all"-->
    <!--        :class="item?.type === 'error' -->
    <!--          ? 'bg-red-500'-->
    <!--          : item?.type === 'success' -->
    <!--            ? 'bg-green-500'-->
    <!--            : item?.type === 'warning'-->
    <!--              ? 'bg-yellow-500'-->
    <!--              : 'bg-blue-500'"-->
    <!--        :style="{ width: `${progress}%` }"-->
    <!--      />-->
    <!--    </div>-->
  </div>
</template>

<script setup>
import { Icon } from "@iconify/vue";
import { onMounted, onUnmounted, ref } from "vue";

const props = defineProps({
  item: { type: Object, required: true }
});

const progress = ref(0);
const interval = ref(null);
const startTime = ref(Date.now());
const pausedTime = ref(0);

const startProgress = () => {
  if (interval.value) return;
  startTime.value = Date.now() - pausedTime.value;

  interval.value = setInterval(() => {
    const elapsed = Date.now() - startTime.value;
    progress.value = Math.min((elapsed / props.item?.duration) * 100, 100);

    if (progress.value >= 100) {
      clearInterval(interval.value);
      interval.value = null;
      props.item?.destroy(); // Close toast
    }
  }, 50);
};

const pauseProgress = () => {
  clearInterval(interval.value);
  interval.value = null;
  pausedTime.value = Date.now() - startTime.value;
};

const resumeProgress = () => {
  startProgress();
};

onMounted(startProgress);
onUnmounted(() => clearInterval(interval.value));
</script>
