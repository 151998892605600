<template>
  <div>
    <Notivue v-slot="item">
      <NotivueSwipe :item="item">
        <NotificationToast :item="item" />
      </NotivueSwipe>
    </Notivue>
    <router-view :avalable-a-p-is="storeAPIs" />
  </div>
</template>

<script setup>
import {Notivue, NotivueSwipe} from 'notivue'
import {useStore} from "vuex";
import {computed} from "vue";
import NotificationToast from "@/components/UI/toast/notivue/NotificationToast.vue";

const store = useStore()

const storeAPIs = computed(() => {
  return store.getters["apis/apis"];
})
</script>

<style>
* {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
}

body {
  min-height: 100vh;
  display: grid;
  grid-template-rows: [header] auto [main] 1fr [footer] auto;

  /* Optional */
  margin: 0;
  line-height: 1.6;
}

main {
  grid-row: content;

  /* Optional */
  padding: 1rem;
  background-color: #ffffff;
  color: #333;
}
</style>