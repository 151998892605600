import { createRouter, createWebHistory } from "vue-router";
import AccessApis from "./pages/AccessApis.vue";
import AnalyticsPage from "./pages/AnalyticsPage.vue";
import NotFound from './pages/NotFound.vue';
import {useAuth} from "@/composables/use-auth";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: "/", redirect: "/sign-in" },
    { path: "/auth", redirect: "/sign-in" },
    {
      name: "access-apis",
      path: "/access-apis",
      meta: { requiresAuth: true },
      components: { default: AccessApis},
    }, 
    {
      name: "analytics",
      path: "/analytics",
      meta: { requiresAuth: true },
      components: { default: AnalyticsPage},
    },
    {
      name: 'sign-in',
      path: '/sign-in',
      component: () =>  import('./pages/auth/SignIn.vue'),
    },
    {
      name: 'sign-up',
      path: '/sign-up',
      component: () =>  import('./pages/auth/SignUp.vue'),
    },
    {
      name: 'confirm-sign-up',
      path: '/sign-up/confirm-sign-up',
      component: () =>  import('./pages/auth/ConfirmSignUp.vue'),
    },
    {
      name: 'confirm-sign-up-from-sign-in',
      path: '/sign-up/confirm-sign-in',
      component: () =>  import('./pages/auth/ConfirmSignupFromSignin.vue'),
    },
    {
      name: 'forgot-password',
      path: '/forgot-password',
      component: () =>  import('./pages/auth/ForgotPassword.vue'),
    },
    {
      name: 'confirm-forgot-password',
      path: '/forgot-password/confirm-forgot-password',
      component: () =>  import('./pages/auth/ConfirmForgotPassword.vue'),
    },
    // {
    //   name: 'set-new-password',
    //   path: '/forgot-password/set-new-password',
    //   component: () =>  import('./pages/auth/SetNewPassword.vue'),
    // },
    // {
    //   name: 'reset-pin',
    //   path: '/forgot-password/reset-pin',
    //   component: () =>  import('./pages/auth/ResetPin.vue'),
    // },
    {
      name: 'maps',
      path: '/maps',
      meta: { requiresAuth: true },
      component: () => import('./pages/MapsPage.vue'),
    },
    {
      name: 'user-profile',
      path: '/user-profile',
      meta: { requiresAuth: true },
      component: () => import('./pages/UserProfilePage.vue'),
    },
    // {
    //   path: "/users",
    //   components: {
    //     default: UsersList,
    //     footer: UsersFooter,
    //   },
    //   beforeEnter(to, from, next) {
    //     console.log("users beforeEnter");
    //     console.log(to, from);
    //     next();
    //   },
    // },
    // { path: '/sign-in', component: UserAuth, meta: { requiresUnauth: true } },
    { path: "/:notFound(.*)", component: NotFound },
  ],
  linkActiveClass: "active",
});

router.beforeEach((to, from) => {
  const { isAuthenticated } = useAuth()

  // For protected routes, redirect to sign-in if not authenticated
  if (to.meta?.requiresAuth && !isAuthenticated.value) {
    return { path: '/sign-in' }
  }

  // Define auth-related paths
  const authPaths = [
    '/sign-in',
    '/sign-up',
    '/sign-up/confirm-sign-up',
    '/sign-up/confirm-sign-in',
    '/forgot-password',
    '/forgot-password/confirm-forgot-password'
  ]

  if (isAuthenticated.value && authPaths.includes(to.path)) {
    return { path: '/maps' }
  }
})

export default router;
